/* -------------------------------------------------------------------------- */
/*                                   Modules                                  */
/* -------------------------------------------------------------------------- */

// Colors
@use '../../../../styles/_colors.scss';

// Mixins
@use '../../../../styles/_mixins.scss';

// Media queries
@use '../../../../styles/_media-queries.scss' as media;

/* -------------------------------------------------------------------------- */
/*                                    Mobile                                  */
/* -------------------------------------------------------------------------- */

.button {
  width: 100%;
  margin-bottom: 8px;
  height: 34.5px;
  background-color: colors.$cr-white-5;
  color: colors.$cr-black-3;
  border: 1px solid colors.$cr-black-3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: colors.$cr-black-3;
    color: colors.$cr-white-1;
  }

  // Remove button margin button on all devices
  &--no-margin-bottom {
    margin-bottom: 0 !important;
  }

  &--disabled {
    background-color: colors.$cr-white-1;
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: colors.$cr-white-1;
      color: initial;
    }
  }

  &--submit {
    width: 40%;
  }

  &__content {
    position: relative;
    display: inline-block;
    letter-spacing: 0.1em;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 1em;

    &__icon {
      position: absolute;
      width: 12px;
      height: auto;
      margin: 0;
      padding: 0;
      top: 50%;
      transform: translate(7%, -50%);
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Tablet                                  */
/* -------------------------------------------------------------------------- */

@include media.device(tablet) {
  .button {
    width: 90%;
    margin-bottom: 20px;
    height: 40.5px;

    &--submit {
      width: 40%;
    }

    &__content {
      font-size: 14px;

      &__icon {
        width: 14px;
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Desktop                                 */
/* -------------------------------------------------------------------------- */

// Small desktop
@include media.device(small-desktop) {
  .button {
    width: 85%;
    height: 38px;
    margin-bottom: mixins.toRem(45);

    &--submit {
      width: 25%;
    }
  }
}

// Medium desktop
@include media.device(medium-desktop) {
  .button {
    width: 62%;

    &--submit {
      width: 25%;
    }
  }
}

// Large desktop
@include media.device(large-desktop) {
  .button {
    width: 55%;

    &--submit {
      width: 25%;
    }
  }
}
